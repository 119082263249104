.about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.about-section p {
  font-size: 22px;
  font-weight: 900;
  float: right;
  margin-left: 20px;

  /* text-align: right; */
}

.text-block,
.about-section ul li {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.text-block.show,
.about-section ul li.show {
  opacity: 1;
  transform: translateY(0);
}

.text-block {
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.about-section ul {
  list-style-type: none;
  padding: 0;
}

.about-section ul li {
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 1.4rem;
  color: #f5f5f5;
}

.signup-container {
  margin-top: 20px;
  text-align: center;
}

.signup-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-button:hover {
  background-color: #0056b3;
}

.signup-text {
  margin-top: 2px;
  font-size: 1rem;
  color: #ffa500;

  /* ................... */

  font-size: 38px;
  line-height: 48px;
  font-family: Verdana, sans-serif;
  font-weight: 900;
  position: relative;
  background: #1d1c1c;
  overflow: hidden;
  text-transform: uppercase;
  text-align: center;
}
.signup-text:before {
  content: "";
  position: absolute;
  filter: blur(10px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 1em,
      #afd4f3 1em,
      #2287e0 50%
    ),
    repeating-linear-gradient(
      45deg,
      #3089d6,
      #2367a0 1em,
      #f5f2f2 1em,
      #b3d7f5 50%
    );
  background-size: 3em 3em, 2em 2em;
  animation-name: ani;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes ani {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@media (max-width: 690px) {
  .signup-text {
    font-size: 18px;
  }
  .about-section ul li {
    font-size: 1rem;
  }
  /* .about-section {
    background-image: url("images/mainMobileFone.jpg");
  } */
}
