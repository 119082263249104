.signup-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.timetable-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.group-card {
  width: 100%;
  max-width: 300px;
  margin: 10px;
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.group-card .group-image {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  /* margin-bottom: -90px; */
}

.group-card h3 {
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: #333;
}

.group-card p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

.signup-button,
.schedule-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-button:hover,
.schedule-button:hover {
  background-color: #0056b3;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.modal-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.close-button {
  padding: 10px 20px;
  background-color: #ff5400;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #e04800;
}

.modal-content ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.modal-content ul li {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #555;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
}
