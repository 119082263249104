.teachers {
  padding: 50px 20px;
  background-color: #fafafa;
  max-width: 800px;
  margin: 0 auto;
}

.teachers h2 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.teachers h3 {
  margin-top: 30px;
}

.teacher-info {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.teacher-info h3 {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 15px;
  text-align: center;
}

.teacher-info p {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .teachers {
    padding: 30px 15px;
  }

  .teachers h2 {
    font-size: 2rem;
  }

  .teacher-info h3 {
    font-size: 1.6rem;
  }

  .teacher-info {
    font-size: 1rem;
  }
  .teachers p {
    font-size: 16px;
  }
}
