.App {
  font-family: "Nunito Sans", sans-serif;
  /* padding-bottom: 100px; */
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 30px; */
  background-color: #333;
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding-left: 50px;
  /* padding-bottom: 150px; */
}

.menu-toggle {
  font-size: 2rem;
  cursor: pointer;
  color: #fff;
  display: none;
}

.nav {
  display: flex;
  gap: 50px;
}

.nav a {
  color: #ffa500;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav a:hover {
  color: #fff;
}

/* .glav-menu li a:hover{ 
  color: #FF5400;
}
.glav-menu li a:focus{ 
  color: #FF5400;	
} */

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav {
    display: none;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    top: 60px;
    right: 0;
    left: 0px;
    background-color: #333;
    width: 100%;
    padding: 20px;
  }

  .nav.open {
    display: flex;
  }
}

.header-contact {
  display: flex;
  align-items: center;
  gap: 35px;
  margin-right: 120px;
}

/* .header-phone {
  font-size: 1.3rem;
} */

/* .header-phone img {
  vertical-align: middle;
  font-size: 1.3rem;
} */

.header-social {
  list-style: none;
  display: flex;
  gap: 35px;
  margin: 0;
  padding: 0;
}

/* .header-social a {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
}

.header-social a:hover {
  color: #ffa500;
} */
