h3 {
  margin-top: 120px;
}

/* .gallery {
  padding: 50px 20px;
  text-align: center;
  background-color: #f3f3f3; 
  margin-top: 100px;
} */

/* .gallery h2 {
  font-size: 2rem;
  color: #333;
} */

/* .gallery-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 20px;
} */

/* .gallery-images img {
  width: 100%;
  border-radius: 8px;
  transition: transform 0.3s ease;
} */

/* .gallery-images img:hover {
  transform: scale(1.05);
} */
