.contacts {
  padding: 50px 20px;
  text-align: center;
  background-color: #f3f3f3;
}

.contacts h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.map-container {
  margin-bottom: 30px;
}

.map-container p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #555;
}

.weather-widget {
  margin: 30px auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.contact-phone {
  font-size: 1.5rem;
  color: #007bff;
  text-decoration: none;
}

.contact-phone:hover {
  color: #0056b3;
}

.socials a {
  margin: 0 10px;
  color: #ffa500;
  text-decoration: none;
  font-size: 1.2rem;
}

.socials a:hover {
  color: #333;
}
